<template>
    <v-card class="overflow-hidden" elevation=0>
        <v-app-bar
            flat
        >
        <v-app-bar-nav-icon @click="setDrawer()"></v-app-bar-nav-icon>
        <v-toolbar-title>Lar Ismael</v-toolbar-title>
        <v-spacer/>
        <v-container fluid style="padding-top: 30px; padding-left: 140px; ">
            <div>Ano da Festa:</div>
            <v-radio-group
                v-model="year"
                row
                @change="setYear($event)"
            >
                <v-radio
                    :label="year.toString()"
                    :value="year"
                    v-for="year in generateArrayOfYears()" :key="year"
                ></v-radio>
            </v-radio-group>
        </v-container>
        <!-- <v-checkbox
          input-value="true"
          value
          v-for="year in generateArrayOfYears()" :key="year"
        >
            <template v-slot:label>
                {{year}}
            </template>
        </v-checkbox> -->
        <v-spacer/>
        <v-btn text @click="logout" class="pl-0" color="grey darken-1">
            <v-icon>mdi-exit-run</v-icon> Sair
        </v-btn>
    </v-app-bar>
  </v-card>
</template>
<script>
    import { mapState, mapActions} from 'vuex'
  export default {
    data: () => ({
        //
        firstYear: 2021,
        year: new Date().getFullYear()
    }),
    mounted() {
        this.year = this.selectedYear
    },
    computed: {
        ...mapState(['drawer', 'defaultYearParty', 'selectedYear'])
    },
    methods: {
        ...mapActions(['logout', 'setSelectYear']),
        setDrawer() {
            this.$store.commit('setDrawer', !this.drawer)
        },
        generateArrayOfYears() {
            var max = new Date().getFullYear()
            var min = this.firstYear
            var years = []

            for (var i = max; i >= min; i--) {
                years.push(i)
            }
            years.push('Cesta Básica')
            return years
        },
        setYear(year) {
            this.setSelectYear(year)
        }
    }
  }
</script>